:root {
    --bc: white;
    --bc-dark: #131420;
    /* colors */
    --primary-color: #23a89a;
    --text-color: #232744;
    --light-color: #F1F2FA;
    --light-primary-color: #7785FF;
    /* radius */
    --radius: 6px;
    /* animation timing */
    --transition-base: cubic-bezier(.645,.045,.355,1);
}

::-webkit-scrollbar {
    width: 8px;
    height: 10%;

}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    width: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-radius: 5px;
    transition: background 200ms;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
}

html {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

html, body {
    overflow-x: hidden;
}
body {
    font-size: 100%;
    color: #525454;

}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 1em;

}

span {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
}

p {
    line-height: 1.67;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    text-shadow: 1px 0 0 currentColor;

}

h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
}

a {
    color: inherit;
    text-decoration: none;
}

svg {
  display: block;
}

[role="button"] {
    cursor: pointer;
}
.disabled-overflow {
  overflow: inherit!important;
}

@keyframes onAutoFillStart {  from {/**/}  to {/**/}}
@keyframes onAutoFillCancel {  from {/**/}  to {/**/}}
input:-webkit-autofill {
animation-name: onAutoFillStart;
transition: background-color 50000s ease-in-out 0s;
}
input:not(:-webkit-autofill) {
animation-name: onAutoFillCancel;
}

[data-clickable="true"] {
    cursor: pointer;
}
.ant-typography {
    color: currentColor;
}

.ant-select-selector, .ant-input {
    border-radius: 25px!important;
}
